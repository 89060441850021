import "./location-bolivia.scss"

import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
 query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "bolivia" }, node_locale: { eq: "en-US" }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
   locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationBoliviaPage = ({ data }) => {
    const pageTitle = "Careers - Bolivia"
    const pageDescription = "Get to Know Our Bolivia Offices"
    const lang = 'EN'
    const meta = []

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "Bolivia", url: "#" }
    ]

    const cochabamba = {
        name: "Cochabamba",
        text: "Cochabamba is the main industrial hub of the country for the production of goods and services. Known as the Silicon Valley of Bolivia, this Andean city hosts the second largest university of the country. Although the city has an elevation of 8,300 ft, it has a very pleasant climate and is sometimes called the city of eternal spring."
    }

    const laPaz = {
        name: "La Paz",
        text: "The highest capital city in the world, at 12,000 ft above sea level, la Paz is the executive capital of Bolivia. Founded on 20 October 1548, la Paz is also an important cultural center of Latin America, with a truly unique culture and scenery.",
        secondText: "La Paz is also the principal center of manufacturing enterprises that produce finished-product goods for the country, with about two-thirds of Bolivia's manufacturing located nearby. The city has several renowned universities that produce very talented software developers."
    }

    const location = {
        title: "Bolivia",
        cities: ["Cochabamba", "La Paz"],
        sectionClassName: "section-location-bolivia",
        modalClassName: "modal-location-bolivia"
    }

    const [cityName, setCityName] = React.useState(cochabamba.name);
    const [cityText, setCityText] = React.useState(cochabamba.text);
    const [secondText, setSecondText] = React.useState(cochabamba.secondText);

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    const setCity = (name) => {
        setCityName(name);
        switch (name) {
            case "La Paz":
                setCityText(laPaz.text);
                setSecondText(laPaz.secondText);
                break;
            case "Cochabamba":
                setCityText(cochabamba.text);
                setSecondText(cochabamba.secondText);
                break;
            default:
                break;
        }
    }

    const cities = location.cities.map((name, index) =>
        <div hidden={name === cityName} key={index} className="list-item" onClick={() => setCity(name)}>{name}</div>
    );

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                <div className="max-container-pages">
                    <LocationHeader location={location} city={cityName}>
                        <span className="city-title">{cityName}</span>
                        <p className="city-text">
                            {cityText}
                        </p>
                        {secondText &&
                            <p>{secondText}</p>
                        }
                        <div className="other-locations">
                            <h5 className="other-locations-title">Other offices in Bolivia</h5>
                            <div className="other-locations-content">
                                {cities}
                            </div>
                        </div>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="Bolivia"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationBoliviaPage
